import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Residence, ResidenceCategory } from "../lib/types/residence";

import { AppDispatch, RootState } from "./store";
import { notifyAndLogError } from "./snackbarSlice";
import {
  subscribeResidenceGroup,
  subscribeResidences,
} from "../Firebase/queries/residences";
import { selectFilterAssignees } from "./assigneeListSlice";
import { ResidenceCategories, SubjectStatusesTerminal } from "../types";
import {
  filterByAssigneesFunc,
  makeFilterSubjectsFunc,
  makeSearchSubjectsFunc,
} from "./subjectResultFuncs";

export interface ResidenceListState {
  residence: Residence | null;
  residences: Residence[];
  related: Residence[];
  statuses: ResidenceCategory[];
  searchText: string;
  loadingResidences: boolean;
}

const isTerminalStatus = (residence: Residence) => {
  return (SubjectStatusesTerminal as ReadonlyArray<string>).includes(residence.status);
};

const initialState: ResidenceListState = {
  residence: null,
  residences: [],
  related: [],
  statuses: [],
  searchText: "",
  loadingResidences: false,
};

const residenceListSlice = createSlice({
  name: "residenceList",
  initialState,
  reducers: {
    setResidence: (state, action: PayloadAction<Residence | null>) => {
      state.residence = action.payload;
    },
    setResidences: (state, action: PayloadAction<Residence[]>) => {
      state.residences = action.payload;
    },
    setRelated: (state, action: PayloadAction<Residence[]>) => {
      state.related = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setStatuses: (state, action: PayloadAction<ResidenceCategory[]>) => {
      state.statuses = action.payload;
    },
    toggleStatus: (state, action: PayloadAction<ResidenceCategory>) => {
      const newStatus = action.payload;
      if (state.statuses.includes(newStatus)) {
        state.statuses = state.statuses.filter((s) => s !== newStatus);
        return;
      }

      state.statuses = [...state.statuses, newStatus];
    },
    setLoadingResidences: (state, action: PayloadAction<boolean>) => {
      state.loadingResidences = action.payload;
    },
  },
});

export const {
  setResidence,
  setResidences,
  setRelated,
  setSearchText,
  toggleStatus,
  setLoadingResidences,
} = residenceListSlice.actions;

export default residenceListSlice.reducer;

// Thunks

export const subscribeResidencesToFirestore =
  (orgId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingResidences(true));
    const { loadingResidences, residence } = getState().residenceList;

    const next = (residences: Residence[]) => {
      dispatch(setResidences(residences));
      if (loadingResidences) dispatch(setLoadingResidences(false));

      if (residence) {
        const currResidence = residences.find((r) => r.id === residence.id);
        dispatch(setResidence(currResidence || null));
      }
    };

    const error = (err: Error) =>
      dispatch(notifyAndLogError(err, "Error loading Residences"));

    return subscribeResidences(orgId).subscribe(next, error);
  };

export const subscribeRelatedToFirestore =
  (orgId: string, epochId: string, groupId: string) => (dispatch: AppDispatch) => {
    const next = (residences: Residence[]) => dispatch(setRelated(residences));

    const error = (err: Error) =>
      dispatch(notifyAndLogError(err, "Error loading Related Residences"));

    return subscribeResidenceGroup(orgId, epochId, groupId).subscribe(next, error);
  };

// Selectors

export const selectLoadingResidences = ({ residenceList }: RootState) =>
  residenceList.loadingResidences;

export const selectResidences = ({ residenceList }: RootState) =>
  residenceList.residences;

export const selectResidence = ({ residenceList }: RootState) => residenceList.residence;

export const selectRelated = ({ residenceList }: RootState) => residenceList.related;

export const selectStatuses = ({ residenceList }: RootState) => residenceList.statuses;

export const selectSearchText = ({ residenceList }: RootState) =>
  residenceList.searchText;

export const selectResidenceWithId = createSelector(
  [selectResidences, (state: RootState, id: string) => id],
  (residences, id) => residences.find((r) => r.id === id) || null
);

export const selectFilteredResidences = createSelector(
  [selectResidences, selectStatuses],
  makeFilterSubjectsFunc(ResidenceCategories)
);

export const selectSearchedResidences = createSelector(
  [selectFilteredResidences, selectSearchText],
  makeSearchSubjectsFunc(["name", "address", "zipCode", "parcelId"])
);

export const selectAssigneesFilterResidences = createSelector(
  [selectSearchedResidences, selectFilterAssignees],
  filterByAssigneesFunc
);

export const selectToDoResidences = createSelector(
  [selectAssigneesFilterResidences],
  (residences) => residences.filter((r) => !isTerminalStatus(r))
);

export const selectResidenceFamily = createSelector(
  [selectResidence, selectRelated],
  (residence, related) => [residence as Residence, ...related]
);

export const selectDoneResidences = createSelector(
  [selectAssigneesFilterResidences],
  (residences) => residences.filter(isTerminalStatus)
);
