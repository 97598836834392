import { useEffect, useMemo } from "react";
import {
  Theme as NavTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import { View } from "react-native";

import { useSUBContext } from "@reasongcp/react-fire-sub";

import { useAppTheme } from "../theme";
import Loading from "../components/loading";
import Snackbar from "../components/snackbar";
import { appLinkingOptions } from "../lib/appNavigation";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { subscribePortalsToFirestore } from "../redux/propertyPortalSlice";
import { selectUser, setUser, subscribeMemberToFirestore } from "../redux/userSlice";

import AuthStack from "./authStack";
import AppStack from "./appStack";

export default function Routes() {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const { authHasLoaded, user: subUser } = useSUBContext();

  const user = useAppSelector(selectUser);

  const navTheme = useMemo(
    () =>
      ({
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          // Prevent a white flash when navigating on devices with animated
          // transitions.
          background: theme.colors.background,
        },
      }) as NavTheme,
    [theme]
  );

  useEffect(() => {
    if (!subUser) {
      return;
    }

    const sub = dispatch(subscribeMemberToFirestore(subUser.uid));
    const subPortals = dispatch(subscribePortalsToFirestore());
    return () => {
      dispatch(setUser(null));
      sub.unsubscribe();
      subPortals.unsubscribe();
    };
  }, [subUser, dispatch]);

  // If the auth has not loaded OR it has, but Redux user data has not yet
  // been initialized, show the loading screen.
  if (!authHasLoaded || (authHasLoaded && subUser && !user)) {
    return <Loading />;
  }

  return (
    <>
      <NavigationContainer linking={appLinkingOptions} theme={navTheme}>
        {user ? <AppStack /> : <AuthStack />}
      </NavigationContainer>
      <View style={{ width: "100%" }}>
        <Snackbar />
      </View>
    </>
  );
}
