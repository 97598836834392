import { FC } from "react";
import { View } from "react-native";
import { Icon, Text } from "react-native-paper";

import StyledText from "../shared/StyledText";

type HomesteadExemptionProps = {
  exemption?: number;
};

type IconColor = "green" | "red" | "grey";
type IconSource = "check-decagram" | "check-decagram-outline";
type IconAttributes = {
  color: IconColor;
  source: IconSource;
};

const iconSource = (exemption?: number): IconAttributes => {
  // No Exemption (must come first because falsy)
  if (exemption === 0) {
    return { color: "red", source: "check-decagram" };
  }

  // Exemption Unknown (null | undefined)
  if (!exemption) {
    return { color: "grey", source: "check-decagram-outline" };
  }

  // 100% exemption
  if (exemption === 100) {
    return { color: "green", source: "check-decagram" };
  }

  // 1-99% exemption
  return { color: "green", source: "check-decagram-outline" };
};

const HomesteadExemption: FC<HomesteadExemptionProps> = ({ exemption }) => {
  const { color, source } = iconSource(exemption);
  return (
    <View
      style={{ marginTop: 6, display: "flex", flexDirection: "row" }}
      aria-label={`Homestead Exemption ${exemption}`}
    >
      <StyledText>
        <Text style={{ fontWeight: "bold" }}>Homestead Exemption: &nbsp;</Text>
      </StyledText>
      <Icon source={source} size={16} color={color} />
    </View>
  );
};

export default HomesteadExemption;
