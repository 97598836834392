import { FC } from "react";
import { View } from "react-native";
import { Icon, Text } from "react-native-paper";

import StyledText from "../shared/StyledText";

type OwnerOccupiedProps = {
  status?: boolean;
};

type Label = "Yes" | "No" | "Unknown";
type IconColor = "green" | "red" | "grey";
type IconSource = "account-box" | "account-box-outline";
type IconAttributes = {
  color: IconColor;
  source: IconSource;
  label: Label;
};

const iconSource = (status?: boolean): IconAttributes => {
  switch (status) {
    case true:
      return { color: "green", source: "account-box", label: "Yes" };
    case false:
      return { color: "red", source: "account-box-outline", label: "No" };
    default:
      return { color: "grey", source: "account-box-outline", label: "Unknown" };
  }
};

const OwnerOccupied: FC<OwnerOccupiedProps> = ({ status }) => {
  const { color, source, label } = iconSource(status);
  return (
    <View
      style={{ marginTop: 6, display: "flex", flexDirection: "row" }}
      aria-label={`Owner Occupied: ${label}`}
    >
      <StyledText>
        <Text style={{ fontWeight: "bold" }}>Owner Occupied: &nbsp;</Text>
      </StyledText>
      <Icon source={source} size={16} color={color} />
    </View>
  );
};

export default OwnerOccupied;
