import React from "react";

import { useAppSelector } from "../../redux/hooks";
import {
  selectResidence,
  selectToDoResidences,
  selectRelated,
} from "../../redux/residenceListSlice";

import CommonMap from "./CommonMap";

const ResidenceMap: React.FC = () => {
  const residence = useAppSelector(selectResidence);
  const residences = useAppSelector(residence ? selectRelated : selectToDoResidences);

  return (
    <CommonMap
      subjectType="Residence"
      selected={residence}
      subjects={residences}
      contextType="residence"
    />
  );
};

export default ResidenceMap;
