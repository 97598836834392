import { useCallback } from "react";
import { Divider, IconButton, Text, Icon } from "react-native-paper";
import { ScrollView, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { useApolloClient } from "@apollo/client";
import { A } from "@expo/html-elements";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import { selectUser } from "../../redux/userSlice";
import { Organization, SubjectStatuses } from "../../types";
import { useAppTheme } from "../../theme";
import { Residence, User } from "../../types";
import ResidenceChip from "../../components/shared/ResidenceChip";
import FormattedAddress from "../shared/FormattedAddress";
import { GPlacesActionItem } from "../shared/GPlacesLink";
import DisplayIfPresent from "../shared/DisplayIfPresent";

import useAppNavigation from "../../hooks/useAppNavigation";
import { useResidenceFromRoute } from "../../hooks/useBusinessFromRoute";
import useSelectSubject from "../../hooks/useSelectSubject";

import { useAppSelector } from "../../redux/hooks";
import { selectOrganization } from "../../redux/organizationSlice";
import { setResidence } from "../../redux/residenceListSlice";

import DisplayLabel from "../shared/DisplayLabel";
import DisplayInfo from "../shared/DisplayInfo";
import ActionFlow from "../shared/ActionFlow";
import PlatformScrollView from "../shared/PlatformScrollView";
import Score from "../shared/Score";
import StyledText from "../shared/StyledText";

import HomesteadExemption from "./HomesteadExemption";
import OwnerOccupied from "./OwnerOccupied";
import ResidenceNotes from "./ResidenceNotes";
import RelatedResidences from "./RelatedResidences";
import residenceStyles from "../DetailStyles";
import { createResidenceCase, dismissResidenceCase } from "../../Firebase/queries/cases";
import ResidenceAttachments from "./ResidenceAttachments";
import partialNotify from "../../lib/partialNotify";
import { useAssignee } from "../../hooks/useAssignee";
import { AssigneeText } from "../shared/AssigneeText";
import {
  confirmCreateCaseMessage,
  confirmDismissMessage,
} from "../../lib/createDismissCaseHelpers";
import TerminalSubjectStatusChip from "../shared/TerminalSubjectChip";
import caseDetailsStylesheet from "../../Styles/caseDetailsStyles";
import SubjectActionItem from "../shared/ActionFlow/SubjectActionItems";
import chipWrapperStyle from "../../Styles/chipWrapper";
import notesAttachmentsStyles from "../../Styles/notesAttachmentsStyles";
import caseCreationErrorNotification from "../../lib/caseCreationErrorNotification";
import { selectPortalUrl } from "../../redux/propertyPortalSlice";

type ResidenceContentProps = {
  organization: Organization;
  residence: Residence;
  user: User;
};

const Tabs = createMaterialTopTabNavigator();

const createConfirmation = partialNotify();
const dismissConfirmation = partialNotify();

const ResidenceContent = ({ organization, user, residence }: ResidenceContentProps) => {
  const navigation = useAppNavigation();
  const theme = useAppTheme();
  const apolloClient = useApolloClient();

  const handleEditPressed = useCallback(() => {
    navigation.navigate("Edit_Residence", { id: residence.id });
  }, [navigation, residence.id]);

  const handleBackPress = useCallback(() => {
    navigation.navigate("Residence_List");
  }, [navigation]);

  const handleCreateCase = useCallback(() => {
    const { residencesEpochId: epochId, id: orgId } = organization;
    if (!epochId) {
      throw new Error("Organization does not have a residences epoch");
    }
    createResidenceCase({
      orgId,
      epochId,
      residence,
      user,
      apolloClient,
    })
      .then((href) => {
        navigation.navigate("Residence_List");
        createConfirmation("Case created", href);
      })
      .catch((error) => {
        console.error("Error creating case", error);
        caseCreationErrorNotification(organization, residence, user);
      });
  }, [navigation, residence, user, apolloClient, organization]);

  const handleDismissCase = useCallback(() => {
    if (!organization.residencesEpochId) {
      return;
    }
    dismissResidenceCase({
      orgId: organization.id,
      epochId: organization.residencesEpochId,
      residenceId: residence.id,
    })
      .then(() => {
        navigation.navigate("Residence_List");
        dismissConfirmation("Case dismissed");
      })
      .catch((error) => {
        console.error("Error dismissing case", error);
      });
  }, [navigation, residence, organization]);

  const assignee = useAssignee(residence.assigneeId);

  const isTerminal =
    residence.status === SubjectStatuses.CASE_CREATED ||
    residence.status === SubjectStatuses.DISMISSED;

  const countyKey = residence.county
    ? `${residence.county.replaceAll(" ", "-")}_${residence.stateAbbr}`.toLowerCase()
    : "unknown";
  const portalUrl = useAppSelector((state) => selectPortalUrl(state, countyKey));
  const {
    metadata: { mpr_id, rentalActivityYear, cleanAddress, resPlaceId },
  } = residence;
  console.log("rentalActivityYear", rentalActivityYear);
  const rentalYear = rentalActivityYear ? String(rentalActivityYear) : null;

  return (
    <PlatformScrollView style={residenceStyles.detailsContainer}>
      <View style={caseDetailsStylesheet.actions}>
        <IconButton icon="arrow-left" onPress={handleBackPress} style={{ margin: 0 }} />
      </View>

      <View style={residenceStyles.detailBlock}>
        <Text style={caseDetailsStylesheet.residenceName}>{residence.name}</Text>
        <FormattedAddress address={residence} />
        <View style={{ marginTop: 6, ...chipWrapperStyle.chipWrapper }}>
          {isTerminal ? (
            <TerminalSubjectStatusChip status={residence.status} />
          ) : (
            <ResidenceChip category={residence.category} />
          )}
        </View>
        <AssigneeText assignee={assignee} />

        <ActionFlow>
          <SubjectActionItem
            isTerminal={isTerminal}
            text="Edit"
            icon="file-edit-outline"
            onPress={handleEditPressed}
          />
          <SubjectActionItem
            isTerminal={isTerminal}
            text="Create Case"
            icon="file-document-outline"
            confirmMessage={isTerminal ? undefined : confirmCreateCaseMessage}
            onPress={handleCreateCase}
          />
          <SubjectActionItem
            isTerminal={isTerminal}
            text="Dismiss"
            icon="close-circle"
            confirmMessage={isTerminal ? undefined : confirmDismissMessage}
            onPress={handleDismissCase}
          />
          <GPlacesActionItem address={cleanAddress} placeId={resPlaceId} />
        </ActionFlow>

        <Divider style={{ marginTop: 6, marginBottom: 6 }} />

        <View>
          <DisplayIfPresent
            label="Original Name"
            text={residence.originalName}
            selectable
          />
          <DisplayIfPresent label="Parcel ID" text={residence.parcelId} selectable />
        </View>

        <View>
          <DisplayLabel text="Contact Information" />
          <FormattedAddress
            address={residence.contact}
            style={{ fontWeight: "400", fontSize: 14 }}
          />
        </View>

        <View style={{ marginTop: 6 }}>
          <Score score={residence.score} boldLabel={true} />
        </View>
        <HomesteadExemption exemption={residence.exemption} />
        <OwnerOccupied status={residence.metadata.ownerOccupied} />
        {rentalYear && (
          <View style={{ marginTop: 6 }}>
            <StyledText>
              <Text style={{ fontWeight: "bold" }}>Rental Year:</Text> {rentalYear}
            </StyledText>
          </View>
        )}

        <View style={{ marginTop: 6 }}>
          <DisplayLabel text="Research Links" />
          {portalUrl && (
            <A target="_blank" rel="noopener noreferrer" href={portalUrl}>
              <DisplayInfo
                text={`${residence.county} ${residence.stateAbbr} Property Portal`}
              />
              &nbsp;
              <Icon source="open-in-new" size={16} />
            </A>
          )}
          {mpr_id && (
            <A
              style={{ marginTop: 3 }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.realtor.com/realestateandhomes-detail/M${mpr_id}`}
            >
              <DisplayInfo text="Search on realtor.com" />
              &nbsp;
              <Icon source="open-in-new" size={16} />
            </A>
          )}
          <A
            style={{ marginTop: 3 }}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.zillow.com/homes/${cleanAddress.replaceAll(" ", "-")}`}
          >
            <DisplayInfo text="Search on zillow.com" />
            &nbsp;
            <Icon source="open-in-new" size={16} />
          </A>
        </View>
      </View>

      <View style={notesAttachmentsStyles.tabViewContainer}>
        <NavigationContainer
          documentTitle={{
            // This feels like a hack. Ensure this sub-navigator doesn't update
            // the page title to something like "Related" or "Notes".
            formatter: () => "Digital Canvas | Residence Insights",
          }}
          independent
        >
          <Tabs.Navigator
            initialRouteName="Related"
            style={notesAttachmentsStyles.tabNav}
            sceneContainerStyle={notesAttachmentsStyles.tabScreen}
            screenOptions={{
              tabBarActiveTintColor: theme.colors.primary,
              tabBarStyle: {
                backgroundColor: theme.colors.background,
              },
              tabBarLabelStyle: {
                color: theme.colors.onBackground,
              },
            }}
          >
            <Tabs.Screen name="Related">
              {() => (
                <ScrollView>
                  <RelatedResidences
                    appNavigation={navigation}
                    organization={organization}
                    residence={residence}
                  />
                </ScrollView>
              )}
            </Tabs.Screen>

            <Tabs.Screen name="Notes">
              {() => (
                <ScrollView>
                  <ResidenceNotes organization={organization} residence={residence} />
                </ScrollView>
              )}
            </Tabs.Screen>

            <Tabs.Screen name="Attachments">
              {() => (
                <ScrollView>
                  <ResidenceAttachments
                    organization={organization}
                    residence={residence}
                  />
                </ScrollView>
              )}
            </Tabs.Screen>
          </Tabs.Navigator>
        </NavigationContainer>
      </View>
    </PlatformScrollView>
  );
};

export default function ResidenceDetails() {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const residence = useResidenceFromRoute();
  useSelectSubject(residence, setResidence);

  if (!residence || !organization || !user) {
    return <></>;
  }

  return (
    <ResidenceContent organization={organization} user={user} residence={residence} />
  );
}
