import { Timestamp } from "firebase/firestore";

import { Address } from "./address";
import { Contact } from "./contact";
import { SubjectStatus } from "./subjectStatuses";

export const ResidenceCategories = [
  "Trust",
  "Living Trust",
  "Irrevocable Trust",
  "Revocable Trust",
  "Business",
  "Rental",
  "Multiple Homes",
  "In State",
  "Out of State",
] as const;

export type ResidenceCategory = (typeof ResidenceCategories)[number];

export type ResidenceMetadata = {
  cleanAddress: string;
  mailingAddress: string;
  resPlaceId: string;
  rentalActivityYear?: number;
  ownerOccupied?: boolean;
  mpr_id?: string;
};

export type ResidenceAttributes = Address & {
  name: string;
  category: ResidenceCategory;
  status: SubjectStatus;
  latitude: number;
  longitude: number;
  score: number;
  exemption: number;
  groupId: string;
  parcelId: string;
  uniqueId: string;
  contact: Contact;
  metadata: ResidenceMetadata;
  assigneeId?: string;
};

export type Residence = ResidenceAttributes & {
  id: string;
  originalName?: string;
  createdAt: Timestamp;
};
