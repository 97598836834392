import { ComponentProps, useMemo } from "react";
import { Text } from "react-native-paper";
import { useAppTheme } from "../../theme";

const StyledText = (props: ComponentProps<typeof Text>) => {
  const { onBackground } = useAppTheme().colors;
  const styles = useMemo(() => ({ color: onBackground }), [onBackground]);

  return <Text {...props} style={styles} />;
};

export default StyledText;
