import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./store";
import { notifyAndLogError } from "./snackbarSlice";
import { PropertyPortal } from "../lib/types/propertyPortal";
import { subscribePortals } from "../Firebase/queries/propertyPortals";

export interface SetUrlPayload {
  key: string;
  url: string;
}

export interface PropertyPortalState {
  urls: Record<string, string>;
}

const initialState: PropertyPortalState = {
  urls: {},
};

export const propertyPortalSlice = createSlice({
  name: "propertyPortals",
  initialState,
  reducers: {
    setUrl: (state, action: PayloadAction<SetUrlPayload>) => {
      state.urls[action.payload.key] = action.payload.url;
    },
    setUrls: (state, action: PayloadAction<SetUrlPayload[]>) => {
      state.urls = action.payload.reduce(
        (acc, { key, url }) => {
          acc[key] = url;
          return acc;
        },
        {} as Record<string, string>
      );
    },
  },
});

export const { setUrl, setUrls } = propertyPortalSlice.actions;

export default propertyPortalSlice.reducer;

// thunks

export const subscribePortalsToFirestore = () => (dispatch: AppDispatch) => {
  const next = (portals: PropertyPortal[]) => {
    dispatch(setUrls(portals.map(({ id, url }) => ({ key: id, url }))));
  };

  const error = (err: Error) => dispatch(notifyAndLogError(err, "Error loading Portals"));

  return subscribePortals().subscribe(next, error);
};

export const selectPortalUrls = ({ propertyPortals }: RootState) => propertyPortals.urls;

export const selectPortalUrl = createSelector(
  [selectPortalUrls, (_: RootState, key: string) => key],
  (urls, key) => urls[key]
);
